import explanationPic1 from "images/home/how-it-works/first.webp";
import explanationPic4 from "images/home/how-it-works/fourth.webp";
import explanationPic2 from "images/home/how-it-works/second.webp";
import explanationPic3 from "images/home/how-it-works/third.webp";
import Link from "next/link";
import Carousel from "nuka-carousel";
import { useState } from "react";

import { Button } from "ui/Button";
import { useI18n } from "utils/with-i18n.utils";

import { CarouselExplanation } from "./CarouselExplanation";
import { CarouselImage } from "./CarouselImage";
import { ExplanationBlock } from "./ExplanationBlock";

const EXPLANATIONS = [
  {
    title: "homepage.explain.title1",
    description: "homepage.explain.subtitle1",
    image: explanationPic1,
  },
  {
    title: "homepage.explain.title2",
    description: "homepage.explain.subtitle2",
    image: explanationPic2,
  },
  {
    title: "homepage.explain.title3",
    description: "homepage.explain.subtitle3",
    image: explanationPic3,
  },
  {
    title: "homepage.explain.title4",
    description: "homepage.explain.subtitle4",
    image: explanationPic4,
  },
];

export const HowItWorks = () => {
  const i18n = useI18n();
  const [textIndex, setTextIndex] = useState(0);

  const handleSelectText = (index: number) => setTextIndex(index);

  return (
    <section className="flex flex-col items-center py-12 px-8 text-white bg-cornflower-blue lg:py-24">
      <h2 className="mb-10 text-4xl font-medium text-center lg:mb-20 lg:text-6xl">
        {i18n.t("homepage.explain.header")}
      </h2>
      <div className="hidden justify-between w-full max-w-5xl lg:flex">
        <Carousel
          swiping={false}
          dragging={false}
          slideIndex={textIndex}
          withoutControls
          className="max-w-lg"
        >
          {EXPLANATIONS.map(({ image }, index) => (
            <CarouselImage key={index} src={image} />
          ))}
        </Carousel>

        <article className="mt-20 max-w-md">
          {EXPLANATIONS.map(({ title, description }, index) => {
            const isActive = index === textIndex;

            return (
              <ExplanationBlock
                title={title}
                isActive={isActive}
                description={description}
                key={index}
                onSelectText={() => handleSelectText(index)}
              />
            );
          })}
          <Button className="mt-10 ml-8 text-black">
            <Link href="/smartphone">
              <a>{i18n.t("homepage.cta")}</a>
            </Link>
          </Button>
        </article>
      </div>
      <div className="flex flex-col items-center lg:hidden">
        <Carousel
          className="max-w-[350px]"
          defaultControlsConfig={{
            nextButtonClassName: "hidden",
            prevButtonClassName: "hidden",
            pagingDotsStyle: {
              fill: "white",
              background: "white",
              borderRadius: "100%",
              height: "10px",
              width: "10px",
              border: "1px solid white",
              marginBottom: "220px",
              marginRight: "5px",
            },
          }}
        >
          {EXPLANATIONS.map(({ description, image, title }, index) => (
            <CarouselExplanation
              key={index}
              src={image}
              description={description}
              title={title}
            />
          ))}
        </Carousel>
        <Link href="/smartphone">
          <a aria-label="choisir un telephone">
            <Button className="text-black">
              {i18n.t("homepage.cta")}
            </Button>
          </a>
        </Link>
      </div>
    </section>
  );
};
