import Link from "next/link";

import { BestSellersCatalog } from "shared/catalog/BestSellersCatalog";
import { Button } from "ui/Button";
import { CheckeredContainer } from "ui/CheckeredContainer";
import { useI18n } from "utils/with-i18n.utils";

export const BestSellers = () => {
  const i18n = useI18n();

  return (
    <CheckeredContainer className="flex flex-col items-center py-12 lg:py-24">
      <h2 className="text-4xl font-medium text-center lg:text-6xl">
        {i18n.t("homepage.bestseller.title")}
      </h2>
      <div className="flex flex-wrap gap-4 justify-center px-5 my-8 w-full max-w-6xl lg:my-12">
        <BestSellersCatalog />
      </div>
      <Link href="/smartphone">
        <a aria-label="voir tous nos telephones">
          <Button>
            {i18n.t("homepage.catalog.cta")}
          </Button>
        </a>
      </Link>
    </CheckeredContainer>
  );
};
